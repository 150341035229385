import { buildStructuredDataSnippet } from "@travellocal/utils";

export type OGData = Partial<{
  type: null | `article`;
  createdDate: string;
  modifiedDate: string;
  publishedDate: string;
  imageSrc: string;
  title: string;
  description: string;
  author: string;
}>;

export const buildStructuredDataForContent = (content: OGData, canonicalUrl: string) => {
  if (!content) {
    return undefined;
  }

  const commonData = {
    image: content.imageSrc,
    mainEntityOfPage: canonicalUrl,
    datePublished: content.publishedDate,
    dateCreated: content.createdDate,
    dateModified: content.modifiedDate,
    description: content.description,
  };

  let contentData: object = {};
  if (content.type === `article`) {
    contentData = {
      headline: content.title,
      author: {
        "@type": "Person",
        name: content.author,
      },
      publisher: {
        "@type": "Organization",
        name: "TravelLocal",
        logo: {
          type: "ImageObject",
          url: "https://www.travellocal.com/images/logo-accent.png",
        },
      },
    };
  }

  return buildStructuredDataSnippet(content.type, {
    ...commonData,
    ...contentData,
  });
};
